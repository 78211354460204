'use strict';

(function() {
  class AddEditTurbineCtrl {
    constructor($scope, Auth, Site, $uibModal, $state, usSpinnerService) {
      this.$scope = $scope;
      this.Site = Site;
      this.$uibModal = $uibModal;
      this.turbine = {};
      this.loading = false;
      this.usSpinnerService = usSpinnerService;
      Auth.isLoggedInAsync((loggedIn)=> {
        if (loggedIn) {
          // this.init();
        } else {
          $state.go('login');
        }
      });
    }

    addTurbine() {
      return turbine=> {
        this.$scope.site.turbines.push(turbine.data);
        // this.$scope.$emit('turbineAdded', {site: this.$scope.site, turbine: turbine.data});
        // console.log(turbine);
      }
    }

    openAddTurbineModal(data = {}, update = false) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/turbine/addEditTurbineModal/addEditTurbineModal.html',
        controller: 'AddEditTurbineModalCtrl',
        controllerAs: 'aETurbine',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Add New Turbine',
            site: this.$scope.site
          }
        }
      });
      modalInstance.result.then();
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditTurbineCtrl', AddEditTurbineCtrl);
})();
